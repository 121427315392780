import sliderMain from 'sliderMain/sliderMain'
import timelineSs from 'timelineSs/timelineSs'
import portfolioList from 'portfolioList/portfolioList'
import portfolioPage from 'portfolioPage/portfolioPage'
import contacts from './components/contacts'

contacts()
portfolioPage()
portfolioList()
timelineSs()
sliderMain()


document.querySelector('.footer__inner').appendChild(document.querySelector('h1.copyright'))
document.querySelector('h1.copyright').insertAdjacentHTML('afterBegin', '&copy;');

// Прячем боковое меню, переключение слайдов на главной
// ===========================================
setTimeout(() => {
    document.body.classList.add('load-time-800');
}, 800)

setTimeout(() => {
    document.body.classList.add('load-time-1600');
}, 1200)

;(() => {
    const topmenuLink = document.querySelector('.topmenu-link')
    const topmenu = document.querySelector('.topmenu')
    const topmenuClose = topmenu.querySelector('.topmenu__close')
    const topmenuDeeper = topmenu.querySelector('.topmenu__link.deeper')
    topmenuLink.addEventListener('click', () => {
        topmenu.classList.add('active')
        setTimeout(() => {
            topmenuDeeper.classList.add('active')
        }, 500)
    })

    topmenuClose.addEventListener('click', () => {
        topmenuDeeper.classList.remove('active')
        topmenu.classList.remove('active')
    })
})()


// Раскрытие/закрытие доп. информации на внутренней странице Портфолио
// ===========================================
const collapseLink = document.querySelector('.portfolio-jumbotron .collapse-link')
if (collapseLink) {
    collapseLink.addEventListener('click', () => {
        document.querySelector('.portfolio-jumbotron .collapse-content').classList.toggle('active')
    })
}
