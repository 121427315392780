export default function() {
    const roadAtlasStyles = [
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ saturation: -100 }, { lightness: -8 }, { gamma: 1.18 }],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ saturation: -100 }, { gamma: 1 }, { lightness: -24 }],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ saturation: -100 }],
        },
        {
            featureType: 'administrative',
            stylers: [{ saturation: -100 }],
        },
        {
            featureType: 'transit',
            stylers: [{ saturation: -100 }],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ saturation: -100 }],
        },
        {
            featureType: 'road',
            stylers: [{ saturation: -100 }],
        },
        {
            featureType: 'administrative',
            stylers: [{ saturation: -100 }],
        },
        {
            featureType: 'landscape',
            stylers: [{ saturation: -100 }],
        },
        {
            featureType: 'poi',
            stylers: [{ saturation: -100 }],
        },
        {},
    ]

    class Map {
        constructor() {
            this.addressesEls = document.querySelectorAll('.contact-item')
            this.sliderContainer = document.querySelector('#address-carousel')
        }

        init() {
            if (!this.addressesEls.length) return
            this.mapInit()
            this.sliderInit()
            this.events()
        }

        sliderInit() {
            const self = this
            self.sliderAddress = tns({
                container: self.sliderContainer,
                items: 1,
                axis: 'vertical',
                controlsContainer: '.carousel-navs',
                nav: false,
                gutter: 8,
                loop: true,
            })
        }

        mapInit() {
            const self = this
            const markers = []
            const locations = []
            const usRoadMapType = new google.maps.StyledMapType(roadAtlasStyles)

            self.addressesEls.forEach(el => {
                const data = el.dataset.position.split(', ')
                locations.push(new google.maps.LatLng(data[0], data[1]))
            })

            self.map = new google.maps.Map(
                document.getElementById('map-canvas'),
                {
                    zoom: 17,
                    center: locations[0],
                    scrollwheel: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    mapTypeControlOptions: {
                        mapTypeIds: [
                            google.maps.MapTypeId.ROADMAP,
                            'usroadatlas',
                        ],
                    },
                }
            )
            locations.forEach(location => {
                markers.push(
                    new google.maps.Marker({
                        position: location,
                        map: self.map,
                    })
                )
            })

            self.map.mapTypes.set('usroadatlas', usRoadMapType)
            self.map.setMapTypeId('usroadatlas')
        }

        events() {
            const self = this
            this.sliderAddress.events.on('transitionEnd', info => {
                const data = info.slideItems[info.index].dataset.position.split(
                    ', '
                )
                const position = new google.maps.LatLng(data[0], data[1])
                self.map.setCenter(position)
            })
        }
    }
    const mapAddresses = new Map()
    mapAddresses.init()
}
