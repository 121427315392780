export default function() {
    ;(() => {
        const sliderContainer = document.querySelector('.js-portfolio-carousel')
        if (!sliderContainer) return
        tns({
            container: sliderContainer,
            mode: 'gallery',
            items: 1,
            animateIn: 'tns-fadeIn',
            animateOut: 'tns-fadeOut',
            speed: 1000,
            controls: false,
            nav: false,
        })
    })()

    ;(() => {
        const sliderThumbs = document.querySelector('.js-portfolio-thumbs')
        if (!sliderThumbs) return
        tns({
            container: sliderThumbs,
            items: 3,
            controlsPosition: 'bottom',
            nav: false,
            gutter: 4,
            responsive: {
                767: {
                    items: 5,
                    gutter: 8,
                },
                1024: {
                    items: 7,
                    gutter: 8,
                },
            },
        })
    })()

    class Pmodal {
        constructor() {
            this.modalContainer = document.querySelector('.modal-dialog');
        }

        init() {
            if (!this.modalContainer) return
            this.modalInit()
            this.events()
        }

        modalInit() {
            const self = this

            const content = self.modalContainer.innerHTML
            /* eslint-disable */
            self.modal = new tingle.modal({
                closeMethods: ['overlay', 'escape'],
                cssClass: ['custom-class-1', 'custom-class-2'],
                onOpen: () => {
                    this.sliderContainer = document.querySelector('.tingle-modal #portfolio-carousel-modal-wrapper .carousel')
                    this.thumbsContainer = document.querySelector('.tingle-modal #portfolio-carousel-modal-wrapper .thumbs')
                    self.slidersInit()
                }
            });
            /* eslint-enable */
            self.modal.setContent(content);
        }

        slidersInit() {
            const self = this
            const id = this.trigger.getAttribute('id')
            const startIndex = id.replace('tns2-item', '')

            if (this.thumbs) {
                this.slider.goTo(startIndex)
            } else {
                ;(() => {
                    if (!self.sliderContainer) return
                    self.slider = tns({
                        container: self.sliderContainer,
                        mode: 'gallery',
                        items: 1,
                        animateIn: 'tns-fadeIn',
                        animateOut: 'tns-fadeOut',
                        speed: 700,
                        controls: false,
                        mouseDrag: true,
                        // nav: false,
                        navContainer: self.thumbsContainer,
                        navAsThumbnails: true,
                        startIndex,
                        autoHeight: true,
                    })
                    self.slider.events.on('transitionEnd', info => {
                        self.thumbs.goTo(info.displayIndex - 1)
                    })
                })()

                ;(() => {
                    if (!self.thumbsContainer) return
                    self.thumbs = tns({
                        container: self.thumbsContainer,
                        items: 7,
                        slideBy: 7,
                        axis: 'vertical',
                        controlsPosition: 'top',
                        nav: false,
                        gutter: 8,
                        loop: false,
                        startIndex,
                    })
                })()
            }
        }

        events() {
            const self = this

            document.querySelectorAll('[data-target="#myModal"]').forEach(el => {
                el.addEventListener('click', () => {
                    self.trigger = el
                    self.modal.open();
                })
            })

            document.querySelector('[data-dismiss="modal"]').addEventListener('click', () => {
                self.modal.close();
            });
            document.querySelector('.tingle-modal').addEventListener('scroll', (e) => {
                if (window.innerWidth <= 991) return
                const close = document.querySelector('.tingle-modal .modal-header .close')
                const thumbs = document.querySelector('.tingle-modal .thumbs-wrapper')
                const top = `${e.currentTarget.scrollTop + 32}px`
                close.style.top = top
                thumbs.style.top = top
            })
        }
    }

    const PortfolioModal = new Pmodal();
    PortfolioModal.init()
}
