export default function() {
	const timelineBlock = document.querySelectorAll('.js-timelineSsItem')
	timelineBlock.forEach(el => {
		const rect = el.getBoundingClientRect()

		if (rect.top > window.innerHeight * 0.75) {
			el.classList.add('is-hidden')
		}
	})
	window.addEventListener('scroll', () => {
		timelineBlock.forEach(el => {
			const rect = el.getBoundingClientRect()
			if (rect.top <= window.innerHeight * 0.75 && el.classList.contains('is-hidden')) {
				el.classList.toggle('is-hidden')
				el.classList.toggle('is-visible')
			}
		})
	})
}
