export default function() {
    const sliderContainer = document.querySelector('.js-sliderMain')
    if (sliderContainer) {
        const slider = tns({
            container: sliderContainer,
            items: 1,
            speed: 500,
            swipeAngle: 30,
            autoplay: false,
            autoplayTimeout: 8000,
            navContainer: '#pager',
            controlsContainer: '.js-sliderMain-arrows',
            autoplayButtonOutput: false,
            responsive: {
                575: {
                    speed: 1300,
                    mode: 'gallery',
                    animateIn: 'tns-fadeIn',
                    animateOut: 'tns-rollOut',
                },
            },
        })
        slider.events.on('transitionEnd', info => {
            const nextButtonTitle = info.nextButton.querySelector(
                '.entry-title'
            )
            const prevButtonTitle = info.prevButton.querySelector(
                '.entry-title'
            )
            let nextIndex = info.displayIndex + 1
            let prevIndex = info.displayIndex - 1
            if (info.displayIndex === info.slideCount) {
                nextIndex = 1
            }
            if (info.displayIndex === 1) {
                prevIndex = info.slideCount
            }
            nextButtonTitle.textContent =
                info.slideItems[nextIndex].dataset.title
            prevButtonTitle.textContent =
                info.slideItems[prevIndex].dataset.title
        })
        const sliderMainSlides = document.querySelectorAll('.sliderMain__slide')
        const setSliderHeight = () => {
            /* eslint-disable */
            Array.prototype.forEach.call(sliderMainSlides, function(el, i) {
                if (window.innerWidth <= 991) {
                    el.style.height = `${window.innerHeight - 67}px`
                } else {
                    el.style.height = `${window.innerHeight - 77 - 67}px`
                }
            })
            /* eslint-enable */
        }
        setSliderHeight()
        window.addEventListener('resize', () => {
            setSliderHeight()
        })
    }
}
