import Wrapper from 'components/wrapper'
import {debounce}  from 'helpers'

export default function() {
    class Slider {
        constructor() {
            this.navPosition = 'bottom'
            this.className = '.js-sliderPortfolio'
        }

        getContainer() {
            return document.querySelector(this.className)
        }

        init() {
            if (!this.getContainer()) return
            this.wrapSlides = new Wrapper({
                container: this.getContainer(),
                selectorWrapperClassName: 'slide-row',
                width: 767
            });
            this.processData()
            this.events()
        }

        processData() {
            if (window.innerHeight <= 768) {
                this.wrapSlides.quantity = 2
            } else {
                this.wrapSlides.quantity = 3
            }
            if (window.innerWidth > 767) {
                this.wrapSlides.wrap();
                this.slider = tns({
                    container: this.getContainer(),
                    navPosition: this.navPosition
                });
            }
        }

        update() {
            if (this.slider && this.slider.isOn) {
                this.slider.destroy()
                this.slider = undefined;
                this.wrapSlides.unwrap();
            }
            this.processData()
        }

        events() {
            window.addEventListener('resize', () => {
                debounce(() => {
                    this.update()
                }, 250)();
            });
        }
    }

    const SliderPortfolio = new Slider();
    SliderPortfolio.init()
}
