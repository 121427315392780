import "@babel/polyfill";
/* eslint-disable */
function ReplaceWithPolyfill() {
  'use-strict'; // For safari, and IE > 10
  var parent = this.parentNode, i = arguments.length, currentNode;
  if (!parent) return;
  if (!i) // if there are no arguments
    parent.removeChild(this);
  while (i--) { // i-- decrements i and returns the value of i before the decrement
    currentNode = arguments[i];
    if (typeof currentNode !== 'object'){
      currentNode = this.ownerDocument.createTextNode(currentNode);
    } else if (currentNode.parentNode){
      currentNode.parentNode.removeChild(currentNode);
    }
    // the value of "i" below is after the decrement
    if (!i) // if currentNode is the first argument (currentNode === arguments[0])
      parent.replaceChild(currentNode, this);
    else // if currentNode isn't the first
      parent.insertBefore(this.previousSibling, currentNode);
  }
}
if (!Element.prototype.replaceWith)
    Element.prototype.replaceWith = ReplaceWithPolyfill;
if (!CharacterData.prototype.replaceWith)
    CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
if (!DocumentType.prototype.replaceWith) 
    DocumentType.prototype.replaceWith = ReplaceWithPolyfill;
/* eslint-enable */

export default class {
    constructor(options = {}) {
        Object.assign(this, {
            mobileFirst: true,
            quantity: 3
        }, options)
        this.id = (new Date).getTime()
        this.container.setAttribute('data-wrapper-id', this.id)
    }

    setVariables() {
        this.container = document.querySelector(`[data-wrapper-id="${this.id}"]`)
        this.newContainer = this.container.cloneNode(true)
        this.arr = [...this.newContainer.children]
    }

    wrap() {
        this.setVariables()
        const condition = this.mobileFirst ? window.innerWidth > this.width : window.innerWidth <= this.width
        if (this.newContainer.querySelector(`.${this.selectorWrapperClassName}`) && this.newContainer.querySelector(`.${this.selectorWrapperClassName}`).children.length === this.quantity) return;
        if (this.mobileFirst && !condition) return;
        if (!this.mobileFirst && !condition) return;

        const docFrag = document.createDocumentFragment();
        for (let i = 0; i < this.arr.length; i += this.quantity) {
            const wrapper = document.createElement('div');
            wrapper.classList.add(this.selectorWrapperClassName);
            this.arr.slice(i, i + this.quantity).forEach(el => {
                wrapper.appendChild(el)
            })
            docFrag.appendChild(wrapper);
        }
        this.newContainer.appendChild(docFrag);
        this.container.replaceWith(this.newContainer);
        // this.container.before(this.newContainer)
        // this.container.insertAdjacentHTML('beforebegin', this.newContainer);
        // this.container.remove()
    }

    unwrap() {
        this.setVariables()
        if (!this.newContainer.querySelector(`.${this.selectorWrapperClassName}`)) return;
        const els = this.newContainer.querySelectorAll(`.${this.selectorWrapperClassName}`);
        els.forEach(el => {
            while (el.firstChild) el.before(el.firstChild);
            el.remove();
        })
        this.container.replaceWith(this.newContainer);
    }
}
